import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Routes,  Route } from 'react-router-dom';

import { ThemeProvider} from '@mui/material/styles';
import PaypsMuiTheme from 'components/graphics/PaypsMuiTheme';

//import {PrivateRoute} from 'components/helpers/PrivateRoute';
import withErrorHandler from 'components/helpers/withErrorHandler';
import LoadingItem from 'components/graphics/LoadingItem';


///SCENES
const LoadableContactForm = lazy(() => import('components/scenes/ContactForm'));

class Router extends Component {

    renderLoading = () => (<LoadingItem />);
    render() {
        return (
            <div className="sceneContainer">
                <ThemeProvider theme={PaypsMuiTheme}>
                    <Suspense fallback={this.renderLoading()}>
                        <Routes>
                            <Route path="/" component={withErrorHandler(LoadableContactForm)} />
                        </Routes>
                    </Suspense>
                </ThemeProvider>
            </div>
        )
    }
}

export default Router;