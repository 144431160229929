import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    languageCode: "fr-FR",
    translatedText: ""
}

export const defineLanguage = createAsyncThunk(
    'translation/defineLanguage',
    async (language) => {
      return language;
    }
);

export const defineTranslation = createAsyncThunk(
  'translation/defineTranslation',
  async (translatedText) => {
    return translatedText;
  }
);

export const translationSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
          .addCase(defineLanguage.fulfilled, (state, action) => {
            state.languageCode = action.payload;
          })
          .addCase(defineTranslation.fulfilled, (state, action) => {
            state.translatedText = action.payload;
          });
      },
  })

export default translationSlice.reducer;