import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    loading: false
}

export const registerContact = createAsyncThunk(
  'contactRegistration/registerContact',
  async (contactData, { getState, rejectWithValue }) => {
    try{
      const apiResponse = await axios.post(process.env.REACT_APP_API_PAYPS+'/contact/create', contactData);
      if(apiResponse.data.valide !== 1){
        return rejectWithValue(apiResponse.data)
      }
      return apiResponse.data;
    }catch(error){
      const rejectedData = {...error.response.data, status: error.response.status}
      return rejectWithValue(rejectedData)
    }
  }
);

export const contactRegistrationSlice = createSlice({
    name: 'contactRegistration',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(registerContact.pending, (state) => {
              console.log("on repasse dans le pending");
                state.loading = 'loading';
              })
            .addCase(registerContact.fulfilled, (state, action) => {
                console.log("on passe dans le fullfiled donc on remet loading à null");
                state.loading = null;
                state.registered = true;
              })
            .addCase(registerContact.rejected, (state) => {
                state.loading = null;
              })
            ;
      },
  })

export default contactRegistrationSlice.reducer;