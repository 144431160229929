import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    messageText: "",
    messageType: "",
    messageShow: false
}

export const showMessage = createAsyncThunk(
    'message/showMessage',
    async (messageData) => {
      return messageData;
    }
);

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
      hideMessage: (state) => {
        state.messageText = "";
        state.messageType = "";
        state.messageShow = false
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(showMessage.fulfilled, (state, action) => {
            state.messageText = action.payload.messageText;
            state.messageType = action.payload.messageType;
            state.messageShow = true;
          });
      },
  })
  
  // Action creators are generated for each case reducer function
export const { hideMessage, showTestMessage } = messageSlice.actions;

export default messageSlice.reducer;