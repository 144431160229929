import {showMessage} from 'features/message/messageSlice';

const checkErrorMiddleware = store => next => action => {
    // On check seulement si l'action nécessite une connexion
    if(action && action.error){
        if(action.payload ){
            const errorAction = {
                messageText: action.payload.message,
                messageType:  "error"
            };
            store.dispatch(showMessage(errorAction));
        }
    }
    return next(action);
}

export default checkErrorMiddleware;