import React, { Component } from "react";
import { connect } from 'react-redux';
import { hideMessage } from 'features/message/messageSlice';
import messageFormater from 'components/helpers/messageFormater';
//import flecheInfo from 'media/images/common/flecheListe.png';


const withErrorHandler = (WrappedComponent) => {
  class HocErrorHandler extends Component {

    constructor(props) {
      super(props);
      this.timer = null;
    }

    componentDidUpdate(){
      // On lance un timeout pour masquer le message au bout de 7 sec
      const { dispatch} = this.props;
      if(this.timer === null){
        this.timer = setTimeout(() => {
          dispatch(hideMessage());
          clearTimeout(this.timer);
          this.timer = null;
          }, 7000);
      }
    }

    onMessageClick = () =>{
      const { dispatch} = this.props;
      dispatch(hideMessage());
    }

    componentWillUnmount(){
      if(this.timer !== null){
        clearTimeout(this.timer);
      }
    }

    render() {
      const { messageText, messageType, messageShow} = this.props;

      return (
        <div className="generalWrapper">
        {
          messageShow && 
            <div className={`internalMessage ${messageType}`} onClick={this.onMessageClick}>
              <div className="textMessage">
                {messageFormater(messageText)}
              </div>
              {
                messageType === "info" &&
                <div className="flecheInfo">
                </div>
              }
            </div>
        }
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }

  const mapStateToProps = (state) => {
    const { messageText, messageType, messageShow} = state.message;
    return {
        messageText,
        messageType,
        messageShow,
    };
  }

  return connect(mapStateToProps)(HocErrorHandler);
}

export default withErrorHandler;