import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Router } from 'react-router-dom';
import WebFont from 'webfontloader';
import history from "components/helpers/history.js";

WebFont.load({
  google: {
    families: ['Ubuntu:300,400,500,600,700:latin', 'Source+Sans+Pro:400,600,700,900:latin&display=swap']
  }
});

let persistor = persistStore(store);

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <Router history={history}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Router>
    </Provider>
  </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
