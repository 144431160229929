import React, {useEffect} from 'react';
import './sass/App.scss';

import { useDispatch } from 'react-redux';
import { defineLanguage, defineTranslation } from 'features/translation/translationSlice';

import Router from 'components/Router';
import LoadingItem from 'components/graphics/LoadingItem';

function App() {
  const dispatch = useDispatch();

  const loading = false;


  useEffect(() => {
    dispatch(defineLanguage(navigator.language));
    loadTranslationFile(navigator.language);
    //eslint-disable-next-line
}, []);

  async function loadTranslationFile (language) {
    switch(language){
      case "fr-FR":
        return await import("translations/fr-FR.json")
        .then((data) => {
          dispatch(defineTranslation(data.paypsText));
        });

      case "en-US":
        return await import("translations/en-US.json")
        .then((data) => {
          dispatch(defineTranslation(data.paypsText));
        });

      default:
        return await import("translations/en-US.json")
        .then((data) => {
          dispatch(defineTranslation(data.paypsText));
        });
    }
  }

  return (
    <div className="paypsContainer">
      {loading && 
        <LoadingItem />
      }
      <Router />
    </div>
  );
}


export default App;