import { configureStore } from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import messageReducer from 'features/message/messageSlice';
import contactRegistrationReducer from 'features/contactRegistration/contactRegistrationSlice';
import translationReducer from 'features/translation/translationSlice';

import checkErrorMiddleware from 'app/middlewares/checkErrorMiddleware';

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'payps-net',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  message: messageReducer,
  contactRegistration: contactRegistrationReducer,
  translation: translationReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
              getDefaultMiddleware({
                serializableCheck: {
                  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
                }
              }).concat(checkErrorMiddleware)
});
